import React, { Fragment } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import { Layout } from "../../components/Layout"
import CompanyHead from "../../components/CompanyInfoHeader"
import styled, { css } from "styled-components"
import { media } from "../../styles/media"
import { AiOutlineShop } from "react-icons/ai"
import { IconContext } from "react-icons"
import CompanyInfoFooterLinks from "../../components/CompanyInfoFooterLinks"
import { useLocationData } from "../../hooks/locationHook"

const ShopIcon = () => (
  <IconContext.Provider value={{ color: "#E50012" }}>
    <AiOutlineShop />
  </IconContext.Provider>
)

const StyledIntro = styled.div`
  width: 90%;
  margin: 50px auto;
  border-bottom: 1px solid #dadada;
  h2 {
    font-weight: bold;
    font-size: 1.32em;
    margin-bottom: 1.62em;
  }
  .introDiv {
    p {
      line-height: 1.32em;
      padding: 0.32em 0;
    }
  }
  .imgSec {
    width: 80%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-around;
    margin: 30px auto;
    .gatsby-image-wrapper {
      width: 30%;
    }
  }
  ${() =>
    media.sp(css`
      margin: 30px auto;
      .imgSec {
        width: 100%;
        .gatsby-image-wrapper {
          width: 40%;
        }
      }
    `)}
`

const StyledLists = styled.div`
  width: 80%;
  margin: 0 auto;
  .infoList {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-bottom: 1.62em;
    h5 {
      width: 20%;
      font-weight: bold;
    }
    .infoCon {
      width: 78%;
    }
  }
  ${() =>
    media.sp(css`
      width: 80%;
      .infoList h5 {
        width: 100%;
        margin-bottom: 0.62em;
        font-size: 0.9em;
        color: gray;
        text-decoration: underline;
      }
      .infoList .infoCon {
        width: 100%;
      }
    `)}
`

const StyledShop = styled.div`
  width: 80%;
  margin: 50px auto;
  .preSection {
    padding: 0px 0 50px;
    h4 {
      font-weight: bold;
    }
    .lineDes {
      display: block;
      content: "";
      height: 2px;
      border-radius: 3px;
      background: #dadada;
      width: 100%;
      margin-bottom: 15px;
      &:after {
        width: 30%;
        background: gray;
        border-radius: 3px;
        content: "";
        height: 2px;
        display: block;
        margin: 5px 0;
      }
    }
    .shopLn {
      width: 95%;
      display: flex;
      margin: 30px auto;
      flex-flow: row wrap;
      align-items: center;
      border-bottom: #dfdfdf 1px dotted;
      h5 {
        width: 15%;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        color: ${({ theme }) => theme.colors.primary.green};
        svg {
          margin-right: 0.32em;
        }
      }
      p {
        font-size: 0.9em;
        width: 50%;
        padding: 0 0.62em 0;
      }
      .phoneLink {
        width: 20%;
        font-size: 0.9em;
        text-align: center;
      }
      .mapLink {
        width: 15%;
        background: ${({ theme }) => theme.colors.primary.gray};
        color: gray;
        text-align: center;
        border-radius: 2px;
        padding: 0.32em;
        transition: 0.6s;
        &:hover {
          background: black;
          color: white;
        }
      }
    }
  }
  ${() =>
    media.tablet(css`
      .shopLn {
        justify-content: space-between;
        h5 {
          width: 100% !important;
          margin-bottom: 0.62em;
        }
        p {
          width: 50%;
        }
        a.phoneLink {
          width: 20%;
        }
        a.mapLink {
          width: 20% !important;
          font-size: 0.8em;
          padding: 0.62em !important;
        }
      }
    `)}
  ${() =>
    media.sp(css`
      .shopLn {
        p {
          width: 100% !important;
          padding: 0.62em 0 !important;
        }
        a.phoneLink {
          width: 50% !important;
          text-align: left !important;
        }
        a.mapLink {
          width: 30% !important;
        }
      }
    `)}
`

const BakeryDep = ({ location }) => {
  const data = useStaticQuery(query)
  useLocationData(location.pathname)
  return (
    <Layout>
      {data.zoo.nodes.map((panda, index) => (
        <Fragment key={index}>
          <CompanyHead
            heroImage={panda.heroMedia.childImageSharp.fluid}
            logoImage={panda.logoMedia && panda.logoMedia.childImageSharp.fluid}
            logoText={panda.logoAlternativeText}
          />
          <StyledIntro>
            <h2 dangerouslySetInnerHTML={{ __html: panda.titleText }} />
            <div
              className="introDiv"
              dangerouslySetInnerHTML={{ __html: panda.contentsUnderTitle }}
            />
            <div className="imgSec">
              {panda.mediaLists.map(pig => (
                <Image
                  fluid={pig.mediaList.childImageSharp.fluid}
                  key={pig.id}
                />
              ))}
            </div>
          </StyledIntro>

          <StyledLists>
            {panda.companyInfo.map((cow, index) => (
              <div className="infoList" key={index}>
                <h5>{cow.titleName}</h5>
                <div
                  className="infoCon"
                  dangerouslySetInnerHTML={{ __html: cow.companyInfos }}
                />
              </div>
            ))}
          </StyledLists>

          <StyledShop>
            {panda.shopList.map((cat, index) => (
              <div className="preSection" key={index}>
                <h4>{cat.prefucName}</h4>
                <span className="lineDes"></span>
                {cat.shopListBody.map((horse, index) => (
                  <div className="shopLn" key={index}>
                    <h5>
                      <ShopIcon />
                      {horse.shopName}
                    </h5>
                    <p>{horse.shopAddress}</p>
                    <a className="phoneLink" href={horse.shopPhone}>
                      {horse.shopPhone}
                    </a>
                    <a
                      className="mapLink"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={horse.mapUrl}
                    >
                      地図を見る
                    </a>
                  </div>
                ))}
              </div>
            ))}
          </StyledShop>
        </Fragment>
      ))}
      <CompanyInfoFooterLinks backLink="/#anchorBiz" />
    </Layout>
  )
}

export const query = graphql`
  {
    zoo: allStrapiCompanyInfoBlog(filter: { strapiId: { eq: 6 } }) {
      nodes {
        logoMedia {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        logoAlternativeText
        titleText
        contentsUnderTitle
        companyInfo {
          id
          titleName
          companyInfos
        }
        addMisc
        heroMedia {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        mediaLists {
          id
          mediaAlt
          mediaList {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        shopList {
          id
          prefucName
          shopListBody {
            id
            mapUrl
            shopAddress
            shopName
            shopPhone
          }
        }
      }
    }
  }
`

export default BakeryDep
